import React from "react";
import { breakPoints } from "~/utils/variables";
import KeizaisanngyosyoSvg from "~/images/companyLogo/keizaisanngyosyo.svg";
import EbaraSvg from "~/images/companyLogo/ebara.svg";
import JtSvg from "~/images/companyLogo/jt.svg";
import ToyoengineeringSvg from "~/images/companyLogo/toyoengineering.svg";
import TechnoproSvg from "~/images/companyLogo/technopro.svg";
import NihonsoukenSvg from "~/images/companyLogo/nihonsouken.svg";
import MarubeniSvg from "~/images/companyLogo/marubeni.svg";
import YayoiSvg from "~/images/companyLogo/yayoi.svg";
import SaisoncardSvg from "~/images/companyLogo/saisoncard.svg";
import LitalicoSvg from "~/images/companyLogo/litalico.svg";
import TechnologySvg from "~/images/companyLogo/technology.svg";
import CyberagentSvg from "~/images/companyLogo/cyberagent.svg";
import MitsuifudosanrealtySvg from "~/images/companyLogo/mitsuifudosanrealty.svg";
import DenaSvg from "~/images/companyLogo/dena.svg";
import FreeeSvg from "~/images/companyLogo/freee.svg";
import SansanSvg from "~/images/companyLogo/sansan.svg";
import TiisSvg from "~/images/companyLogo/tiis.svg";
import SbisonpoSvg from "~/images/companyLogo/sbisonpo.svg";
import VirtualexSvg from "~/images/companyLogo/virtualex.svg";
import SepteniSvg from "~/images/companyLogo/septeni.svg";
import ItsumoSvg from "~/images/companyLogo/itsumo.svg";
import MkiSvg from "~/images/companyLogo/mki.svg";
import SaintmarcholdingsSvg from "~/images/companyLogo/saintmarcholdings.svg";
import KourakuenSvg from "~/images/companyLogo/kourakuen.svg";
import ViaholdingsSvg from "~/images/companyLogo/viaholdings.svg";
import YakuodoSvg from "~/images/companyLogo/yakuodo.svg";
import ReliaSvg from "~/images/companyLogo/relia.svg";
import WelcomeSvg from "~/images/companyLogo/welcome.svg";
import HidahidakayaSvg from "~/images/companyLogo/hidahidakaya.svg";
import School21Svg from "~/images/companyLogo/school21.svg";
import DoutorSvg from "~/images/companyLogo/doutor.svg";
import YoshinoyaSvg from "~/images/companyLogo/yoshinoya.svg";
import KirindoSvg from "~/images/companyLogo/kirindo.svg";
import SophiamedSvg from "~/images/companyLogo/sophiamed.svg";
import ColowideSvg from "~/images/companyLogo/colowide.svg";
import OrenoSvg from "~/images/companyLogo/oreno.svg";
import KushitanakaSvg from "~/images/companyLogo/kushitanaka.svg";
import ChikaranomotoSvg from "~/images/companyLogo/chikaranomoto.svg";
import GenkySvg from "~/images/companyLogo/genky.svg";
import Bellsystem24Svg from "~/images/companyLogo/bellsystem24.svg";

export const CompanyLogos: React.FC = () => (
  <>
    <style jsx>{`
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
      }
      .listItem {
        margin: 12px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @media (${breakPoints.sp}) {
        .listItem {
          margin: 10px;
        }
      }
    `}</style>
    <ul className="list">
      <li className="listItem">
        <img src={KeizaisanngyosyoSvg} alt="経済産業省" />
      </li>
      <li className="listItem">
        <img src={EbaraSvg} alt="荏原製作所" />
      </li>
      <li className="listItem">
        <img src={JtSvg} alt="日本たばこ産業" />
      </li>
      <li className="listItem">
        <img src={ToyoengineeringSvg} alt="東洋エンジニアリング" />
      </li>
      <li className="listItem">
        <img src={TechnoproSvg} alt="テクノプロテクノプロ・デザイン社" />
      </li>
      <li className="listItem">
        <img src={NihonsoukenSvg} alt="日本総合研究所" />
      </li>
      <li className="listItem">
        <img src={MarubeniSvg} alt="丸紅" />
      </li>
      <li className="listItem">
        <img src={YayoiSvg} alt="弥生" />
      </li>
      <li className="listItem">
        <img src={SaisoncardSvg} alt="クレディセゾン" />
      </li>
      <li className="listItem">
        <img src={LitalicoSvg} alt="LITALICO" />
      </li>
      <li className="listItem">
        <img src={TechnologySvg} alt="アウトソーシングテクノロジー" />
      </li>
      <li className="listItem">
        <img src={CyberagentSvg} alt="サイバーエージェント" />
      </li>
      <li className="listItem">
        <img src={MitsuifudosanrealtySvg} alt="三井不動産リアルティ" />
      </li>
      <li className="listItem">
        <img src={DenaSvg} alt="ディー・エヌ・エー" />
      </li>
      <li className="listItem">
        <img src={FreeeSvg} alt="freee" />
      </li>
      <li className="listItem">
        <img src={SansanSvg} alt="sansan" />
      </li>
      <li className="listItem">
        <img src={TiisSvg} alt="豊田自動織機ITソリューションズ" />
      </li>
      <li className="listItem">
        <img src={SbisonpoSvg} alt="SBI損害保険" />
      </li>
      <li className="listItem">
        <img src={VirtualexSvg} alt="バーチャレクス九州" />
      </li>
      <li className="listItem">
        <img src={SepteniSvg} alt="セプテーニ" />
      </li>
      <li className="listItem">
        <img src={ItsumoSvg} alt="いつも．" />
      </li>
      <li className="listItem">
        <img src={SaintmarcholdingsSvg} alt="サンマルクホールディングス" />
      </li>
      <li className="listItem">
        <img src={KourakuenSvg} alt="幸楽苑ホールディングス" />
      </li>
      <li className="listItem">
        <img src={ViaholdingsSvg} alt="ヴィア・ホールディングス" />
      </li>
      <li className="listItem">
        <img src={YakuodoSvg} alt="薬王堂" />
      </li>
      <li className="listItem">
        <img src={ReliaSvg} alt="りらいあコミュニケーションズ" />
      </li>
      <li className="listItem">
        <img src={WelcomeSvg} alt="ウェルカム" />
      </li>
      <li className="listItem">
        <img src={HidahidakayaSvg} alt="ハイデイ日高" />
      </li>
      <li className="listItem">
        <img src={School21Svg} alt="スクール２１" />
      </li>
      <li className="listItem">
        <img src={DoutorSvg} alt="ドトールコーヒー" />
      </li>
      <li className="listItem">
        <img src={YoshinoyaSvg} alt="吉野家" />
      </li>
      <li className="listItem">
        <img src={KirindoSvg} alt="キリン堂" />
      </li>
      <li className="listItem">
        <img src={SophiamedSvg} alt="ソフィアメディ" />
      </li>
      <li className="listItem">
        <img src={ColowideSvg} alt="コロワイドグループ" />
      </li>
      <li className="listItem">
        <img src={OrenoSvg} alt="俺の" />
      </li>
      <li className="listItem">
        <img src={KushitanakaSvg} alt="串カツ田中" />
      </li>
      <li className="listItem">
        <img src={ChikaranomotoSvg} alt="力の源ホールディングス" />
      </li>
      <li className="listItem">
        <img src={GenkySvg} alt="ゲンキー" />
      </li>
      <li className="listItem">
        <img src={Bellsystem24Svg} alt="ベルシステム24" />
      </li>
    </ul>
  </>
);
